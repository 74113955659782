
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import {
  formatDate,
  formatDateNumber,
  formatTimeNumber,
  parsingErrorResponse,
  formatInputNumberOnly,
  ConvertObjectCamelToSnakeCase,
  checkRolePermission
} from "@/app/infrastructures/misc/Utils";
import formatInputNumberOnlyV2 from "@/app/infrastructures/misc/common-library/FormatInputNumberOnlyV2";
import PlaneTransit from "../components/plane-transit.vue";
import {
  CargoConfiguration,
  CargoConfigurationFlightDetail,
  CargoConfigurationFlightTransitDetail,
  ClientBranch,
  CutOffTimeConfigCargo,
  RequestFormCargoConfiguration,
  SchedulePlane
} from "@/domain/entities/CargoConfiguration";
import { CargoConfigurationController } from "@/app/ui/controllers/CargoConfigurationController";
import {
  EditCargoConfigurationRequest,
  AddCargoConfigurationRequest
} from "@/data/payload/api/CargoConfigurationApiRequest";
import { CityData } from "@/domain/entities/Location";
import formatTimeHoursMinutes from "@/app/infrastructures/misc/common-library/FormatTimeHoursMinutes";
import { CargoController } from "@/app/ui/controllers/CargoController";
import moment from "moment";
import debounce from "lodash/debounce";
import { ClientController } from "@/app/ui/controllers/ClientController";
import {
  ClientData,
  ClientMappingList,
  RequestApiClientMappingList,
} from "@/domain/entities/Client";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import DeleteConfigPopup from "@/app/ui/views/route/cargo-configuration/components/delete-config-popup.vue";
import { CARGO_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    PlaneTransit,
    DeleteConfigPopup
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class CargoConfigurationAdd extends Vue {
  get id() {
    return Number(this.$route.params?.id);
  }
  get isEdit() {
    return !!this.id;
  }

  async mounted() {
    if (this.isEdit) {
      await this.fetchDetailData();
    } else {
      this.onResetCutOffTime();
    }
  }

  form: CargoConfiguration = new CargoConfiguration();

  get filterCutOffTimeByStatus(): CutOffTimeConfigCargo[] {
    if (this.isEdit) {
      return this.form.cargoConfigurationCutOffTime.filter(
        (item: CutOffTimeConfigCargo) => item.ctStatus !== "INACTIVE"
      );
    }
    return this.form.cargoConfigurationCutOffTime;
  }
  addCutOffTime(): void {
    const lastKey = this.form.cargoConfigurationCutOffTime[
      this.form.cargoConfigurationCutOffTime.length - 1
    ].key;
    this.form.cargoConfigurationCutOffTime.push(
      new CutOffTimeConfigCargo({ key: lastKey + 1 })
    );
    this.onValidateCutOffTime();
  }
  formatCutOffTimeValue(params: { value: string; index: number }): void {
    const formIndex = this.form.cargoConfigurationCutOffTime.findIndex(
      (item: CutOffTimeConfigCargo) =>
        item.key === this.filterCutOffTimeByStatus[params.index].key
    );
    this.form.cargoConfigurationCutOffTime[
      formIndex
    ].ctTime = formatTimeHoursMinutes(params.value).toString();

    this.onValidateCutOffTime();
  }
  deleteCutOffTime(index: number): void {
    const data = this.filterCutOffTimeByStatus[index];

    if (this.isEdit && data.ctId) {
      data.ctStatus = "INACTIVE";
    } else {
      const indexData = this.form.cargoConfigurationCutOffTime.findIndex(
        item => item.key === this.filterCutOffTimeByStatus[index].key
      );
      this.form.cargoConfigurationCutOffTime.splice(indexData, 1);
    }
    this.onValidateCutOffTime();
  }
  onValidateCutOffTime(): void {
    if (this.filterCutOffTimeByStatus.length > 1) {
      this.filterCutOffTimeByStatus.forEach((item: any, index: number) => {
        if (index > 0) {
          const before = this.filterCutOffTimeByStatus[index - 1];
          const current = item.ctTime;
          const indexData = this.form.cargoConfigurationCutOffTime.findIndex(
            e => e.key === item.key
          );

          if (before && current) {
            this.form.cargoConfigurationCutOffTime[indexData].isError =
              formatTimeHoursMinutes(current).getTime() <=
              formatTimeHoursMinutes(before.ctTime).getTime();
          }
        }
      });
    }
  }
  get haveErrorCutOffTime(): boolean {
    return !!this.form.cargoConfigurationCutOffTime.find(item => item.isError);
  }
  get haveErrorCutOffPlane(): boolean {
    return !!this.schedulePlanes.find(item => item.isError);
  }
  onResetCutOffTime(): void {
    this.form.cargoConfigurationCutOffTime = [new CutOffTimeConfigCargo()];
  }
  onCutOffTimePlane(): void {
    if (this.isPlane) {
      if (this.isEdit) {
        this.onCutOffTimePlaneEdit();
      } else {
        this.form.cargoConfigurationCutOffTime = [new CutOffTimeConfigCargo()];
        this.cutOffTimePlaneActiveData.forEach(
          (item: SchedulePlane, index: number) => {
            if (item.endTime) {
              if (index === 0) {
                this.form.cargoConfigurationCutOffTime[0] = new CutOffTimeConfigCargo(
                  {
                    ctTime: formatTimeHoursMinutes(item.endTime).toString()
                  }
                );
              } else {
                this.form.cargoConfigurationCutOffTime.push(
                  new CutOffTimeConfigCargo({
                    ctTime: formatTimeHoursMinutes(item.endTime).toString()
                  })
                );
              }
            }
          }
        );
      }
    }
  }
  onCutOffTimePlaneEdit(): void {
    const cutOffData = this.form.cargoConfigurationCutOffTime;
    this.schedulePlanes.forEach((item: SchedulePlane) => {
      const existingData = cutOffData.find(
        (e: CutOffTimeConfigCargo) => e.key === item.key
      );
      const idxChangeStatus = cutOffData.findIndex(
        (e: CutOffTimeConfigCargo) =>
          e.key === item.key && !e.ctStatus.match(`/^${item.status}$/gi`)
      );

      if (item.endTime && !existingData) {
        this.form.cargoConfigurationCutOffTime.push(
          new CutOffTimeConfigCargo({
            ctCcId: this.id,
            ctTime: formatTimeHoursMinutes(item.endTime).toString(),
            ctStatus: item.status,
            key: cutOffData[cutOffData.length - 1].key + 1
          })
        );
      } else if (idxChangeStatus > -1) {
        this.form.cargoConfigurationCutOffTime[
          idxChangeStatus
        ].ctTime = formatTimeHoursMinutes(item.endTime).toString();
        this.form.cargoConfigurationCutOffTime[idxChangeStatus].ctStatus =
          item.status;
      }
    });
  }

  formatInputNumber(value: string) {
    return formatInputNumberOnly(value);
  }
  formatDate(date: string) {
    return formatDate(date);
  }

  formatFloat(value: string) {
    return value
      .replace(/[^0-9,.]+/g, "")
      .replace(/\.|\.,|,,|,\./g, ",")
      .replace(/,(\d{2})\d+/g, ",$1");
  }

  // tansportation type
  optionTransportation = [
    {
      name: "Truk",
      value: "TRUCK"
    },
    {
      name: "Kereta",
      value: "TRAIN"
    },
    {
      name: "Kapal",
      value: "SHIP"
    },
    {
      name: "Pesawat",
      value: "PLANE"
    }
  ];
  selectTransportation(item: any) {
    if ((this.form.transportationType as OptionsClass).value === item.value) {
      this.form.transportationType = new OptionsClass();
    } else {
      this.form.transportationType = new OptionsClass({
        name: item.name,
        value: item.value
      });

      if (item.value === "PLANE") {
        this.form.vendor = "";
        this.initAutoFill();
      } else {
        this.onResetCutOffTime();
      }
    }
  }
  get isShowVendor() {
    return !!(this.form.transportationType as OptionsClass).value.match(
      /TRUCK|TRAIN|SHIP/gi
    );
  }
  get isPlane(): boolean {
    return !!(this.form.transportationType as OptionsClass).value.match(
      /PLANE/gi
    );
  }
  async initAutoFill(): Promise<void> {
    await this.fetchAirportOrigin(
      (this.form.originCity as CityData).code || ""
    );
    await this.fetchAirportDestination(
      (this.form.destinationCity as CityData).code
    );
    if (this.form.originCity && this.form.destinationCity) {
      const initialDataOriginAirport = CargoController.airportDataOrigin.data;
      const initialDataDestinationAirport =
        CargoController.airportDataDestination.data;
      this.schedulePlanes[0].airportOrigin = initialDataOriginAirport.length
        ? initialDataOriginAirport
            .map(item => ({
              ...item,
              airportName: `${item.airportCode} - ${item.airportName}`
            }))
            .reduce((acc, item) => ({
              ...acc,
              item
            }))
        : "";
      this.schedulePlanes[0].airportDestination = initialDataDestinationAirport.length
        ? initialDataDestinationAirport
            .map(item => ({
              ...item,
              airportName: `${item.airportCode} - ${item.airportName}`
            }))
            .reduce((acc, item) => ({
              ...acc,
              item
            }))
        : "";
    }
  }
  fetchAirportOrigin(search: string, hideDefault = true) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    return CargoController.getAirportListOrigin({
      airportCode: "",
      cityCode: search,
      page: 1,
      limit: 10,
      isLocation: true,
      hideDefault: hideDefault
    });
  }
  fetchAirportDestination(search: string) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    return CargoController.getAirportListDestination({
      airportCode: "",
      cityCode: search,
      page: 1,
      limit: 10,
      isLocation: true
    });
  }

  leavePageConfirmation = false;
  saveConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    this.$router.push(
      this.isEdit
        ? `/network/cargo-configuration/${this.id}`
        : "/network/cargo-configuration"
    );
  }
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  onSaveForm() {
    if (this.isEdit) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Konfirmasi Perubahan Konfigurasi",
          message: "Data yang anda ubah akan otomatis diperbarui",
          textCancel: "Kembali",
          textSuccess: "Simpan",
          onSubmit: () => this.saveConfig(),
          onClose: () => MainAppController.closeMessageModal(),
          image: "are-you-sure"
        })
      );
      return;
    }
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Simpan Konfigurasi Kargo Baru?",
        message:
          "Konfigurasi kargo baru yang Anda buat akan tersimpan di Aplikasi",
        textCancel: "Tidak",
        textSuccess: "Ya",
        onSubmit: () => this.saveConfig(),
        onClose: () => MainAppController.closeMessageModal(),
        image: "leaving-page-illustration"
      })
    );
  }

  get cargoFlightPayload(): CargoConfigurationFlightDetail[] {
    if (this.cutOffTimePlaneActiveData.length && this.isPlane) {
      return this.cutOffTimePlaneActiveData.map((item: SchedulePlane) => {
        const departure =
          item.departureDate && item.departureTime
            ? new Date(
                `${formatDateNumber(item.departureDate)} ${formatTimeNumber(
                  item.departureTime
                )}`
              ).toISOString()
            : "";
        const formatedDeparturedHour =
          formatTimeNumber(item.departureTime).slice(0, 5) + ":00";
        const formatedFlightEndTime =
          moment(item.endTime)
            .toISOString()
            .slice(0, 16) + ":00.00Z";

        return new CargoConfigurationFlightDetail({
          cargoFlightDay: (item.flightDay as OptionsClass).value,
          cargoFlightEndTime: formatedFlightEndTime,
          cargoFlightDepartureTime: departure,
          cargoFlightDepartureHour: formatedDeparturedHour,
          cargoFlightOrigin: item.airportOrigin.airportCode,
          cargoFlightDestination: item.airportDestination.airportCode,
          cargoFlightDayTransit: item.dayTransit ? item.dayTransit.value : 0,
          cargoFlightTreshold: Number(item.flightTreshold),
          cargoFlightTransit: this.cargoFlightTransitPayload(item)
        });
      });
    }
    return [];
  }
  cargoFlightTransitPayload(item: SchedulePlane): any {
    return item.transit.length
      ? item.transit.map((e: SchedulePlane) => {
          const departureTransit =
            e.departureDate && e.departureTime
              ? new Date(
                  `${formatDateNumber(e.departureDate)} ${formatTimeNumber(
                    e.departureTime
                  )}`
                ).toISOString()
              : "";
          const formatedDeparturedHour =
            formatTimeNumber(e.departureTime).slice(0, 5) + ":00";

          return ConvertObjectCamelToSnakeCase(
            new CargoConfigurationFlightTransitDetail({
              cargoFlightDay: "",
              cargoFlightDepartureTime: departureTransit,
              cargoFlightDepartureHour: formatedDeparturedHour,
              cargoFlightOrigin: e.airportOrigin.airportCode,
              cargoFlightDestination: e.airportDestination.airportCode,
              cargoFlightDayTransit: e.dayTransit.value ? e.dayTransit.value : 0,
              cargoFlightTreshold: Number(e.flightTreshold)
            })
          );
        })
      : [];
  }
  get cutOffTimePayload(): any {
    if (this.form.cargoConfigurationCutOffTime.length) {
      const addedDataActive = this.form.cargoConfigurationCutOffTime.filter(
        (item: CutOffTimeConfigCargo) =>
          item.ctId || (!item.ctId && item.ctStatus.match(/^active$/gi))
      );
      return addedDataActive
        .filter(item => item.ctTime)
        .map(
          (item: CutOffTimeConfigCargo) =>
            new CutOffTimeConfigCargo({
              ...item,
              ctCcId: this.id,
              ctTime: formatTimeNumber(item.ctTime),
              ctFlight: {}
            })
        );
    }
    return [];
  }
  get cutOffTimePlanePayload(): any {
    if (this.schedulePlanes.length) {
      const addedDataActive = this.isEdit
        ? this.schedulePlanes
        : this.schedulePlanes.filter((item: SchedulePlane) =>
            item.status.match(/^active$/gi)
          );
      return addedDataActive
        .filter(item => item.endTime)
        .map((item: any) => {
          const departure: any =
            item.departureDate && item.departureTime
              ? new Date(
                  `${formatDateNumber(item.departureDate)} ${formatTimeNumber(
                    item.departureTime
                  )}`
                ).toISOString()
              : "";
          const formatedDeparturedHour =
            formatTimeNumber(item.departureTime).slice(0, 5) + ":00";
          const formatedCtTime =
            formatTimeNumber(item.endTime).slice(0, 5) + ":00";
          const formatedFlightEndTime =
            moment(item.endTime)
              .toISOString()
              .slice(0, 16) + ":00.00Z";

          return new CutOffTimeConfigCargo({
            ctId: item.ctId,
            ctCcId: this.id,
            ctTime: formatedCtTime,
            ctStatus: this.isEdit ? item.status : "",
            ctFlight: ConvertObjectCamelToSnakeCase(
              new CargoConfigurationFlightDetail({
                cargoFlightDay: (item.flightDay as OptionsClass).value,
                cargoFlightEndTime: formatedFlightEndTime,
                cargoFlightDepartureTime: departure,
                cargoFlightDepartureHour: formatedDeparturedHour,
                cargoFlightOrigin: item.airportOrigin.airportCode,
                cargoFlightDestination: item.airportDestination.airportCode,
                cargoFlightDayTransit: item.dayTransit.value,
                cargoFlightTreshold: Number(item.flightTreshold),
                cargoFlightTransit: this.cargoFlightTransitPayload(item)
              })
            ),
            ctFlightDay: (item.flightDay as OptionsClass).value,
          });
        });
    }
    return [];
  }
  get payload(): any {
    return new RequestFormCargoConfiguration({
      cargoConfigurationId: this.id,
      cargoConfigurationOrigin: (this.form.originCity as CityData).code,
      cargoConfigurationOriginName: (this.form.originCity as CityData).name,
      cargoConfigurationDestination: (this.form.destinationCity as CityData)
        .code,
      cargoConfigurationDestinationName: (this.form.destinationCity as CityData)
        .name,
      cargoConfigurationModeTransport: (this.form
        .transportationType as OptionsClass).value,
      cargoConfigurationComodityCode: this.form.joinCommoditiesCode(";"),
      cargoConfigurationComodityName: this.form.joinCommoditiesName(";"),
      cargoConfigurationProductType: this.form.joinProducts(";"),
      cargoConfigurationClientParentId: this.clientValue?.id || 0,
      cargoConfigurationClientParentName: this.clientValue?.name || "",
      cargoConfigurationClientBranch: this.mappedCargoConfigurationClientBranch.length
        ? this.mappedCargoConfigurationClientBranch
        : [],
      cargoConfigurationMaxPieceLimit: Number(
        String(this.form.maximumPieces).replaceAll(".", "")
      ),
      cargoConfigurationMinimumWeight: Number(
        String(this.form.minimumWeight).replace(",", ".")
      ),
      cargoConfigurationMaximumWeight: Number(
        String(this.form.maximumWeight).replace(",", ".")
      ),
      cargoConfigurationMinimumVolume: Number(
        String(this.form.minimumVolume).replace(",", ".")
      ),
      cargoConfigurationMaximumVolume: Number(
        String(this.form.maximumVolume).replace(",", ".")
      ),
      cargoConfigurationStatus: this.form.status,
      cargoConfigurationVendorCode: "",
      cargoConfigurationVendorName: "",
      cargoFlight: this.cargoFlightPayload,
      cargoConfigurationCutOffTime: this.isPlane
        ? this.cutOffTimePlanePayload
        : this.cutOffTimePayload
    });
  }
  async saveConfig() {
    MainAppController.closeErrorMessage();
    MainAppController.closeMessageModal();
    MainAppController.showLoading();
    try {
      if (this.isEdit) {
        await CargoConfigurationController.onEdit(
          new EditCargoConfigurationRequest({
            payload: this.payload
          })
        );
      } else {
        await CargoConfigurationController.addCargoConfiguration(
          new AddCargoConfigurationRequest({ payload: this.payload })
        );
      }
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: `${
            this.isEdit ? "Perubahan" : "Pembuatan"
          } Konfigurasi kargo Berhasil !`,
          message: this.isEdit
            ? "Data konfigurasi kargo berhasil diubah"
            : "Konfigurasi Kargo yang Anda buat berhasil tersimpan",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success"
        })
      );
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          `${this.isEdit ? "Perubahan" : "Pembuatan"} Gagal!`,
          () => this.saveConfig()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
  get isFormValid() {
    this.onCutOffTimePlane();
    let isFlightsValid = true;

    if (
      (this.form.transportationType as OptionsClass).value.match(/^plane$/gi) &&
      this.cutOffTimePlaneActiveData.length
    ) {
      isFlightsValid = !this.cutOffTimePlaneActiveData.find(item => {
        if (item.transit.length) {
          return !!item.transit.find(
            e =>
              !item.endTime ||
              !item.departureTime ||
              !e.departureTime ||
              !Number(item.flightTreshold) ||
              !Number(e.flightTreshold) ||
              !item.airportOrigin ||
              !item.airportDestination ||
              !e.airportDestination ||
              !e.airportOrigin ||
              Number(e.flightTreshold) > 1440 ||
              Number(item.flightTreshold) > 1440
          );
        }
        return (
          !item.endTime ||
          !item.departureTime ||
          !Number(item.flightTreshold) ||
          !item.airportOrigin ||
          !item.airportDestination ||
          Number(item.flightTreshold) > 1440
        );
      });
    }

    return (
      !!this.form.originCity &&
      !!this.form.destinationCity &&
      !!(this.form.transportationType as OptionsClass)?.value &&
      !!this.form.commodities.length &&
      !!this.form.minimumWeight &&
      !!this.form.maximumWeight &&
      Number(this.form.minimumWeight.replace(",", ".")) <
        Number(this.form.maximumWeight.replace(",", ".")) &&
      !!this.form.minimumVolume &&
      !!this.form.maximumVolume &&
      Number(this.form.minimumVolume.replace(",", ".")) <
        Number(this.form.maximumVolume.replace(",", ".")) &&
      !this.isInvalidVolumeOrWeight &&
      isFlightsValid &&
      !!this.form.cargoConfigurationCutOffTime[0].ctTime &&
      !this.form.cargoConfigurationCutOffTime.find((item) => !item.ctTime) &&
      !this.haveErrorCutOffTime &&
      !this.haveErrorCutOffPlane &&
      !!this.form.maximumPieces &&
      this.isValidMaxPieces
    );
  }
  get isValidMaxPieces(): boolean {
    return this.form.maximumPieces && this.isPlane
      ? Number(formatInputNumberOnlyV2(this.form.maximumPieces)) <= 15
      : true;
  }

  schedulePlanes: SchedulePlane[] = [new SchedulePlane()];
  get cutOffTimePlaneActiveData(): SchedulePlane[] {
    if (this.schedulePlanes.length) {
      return this.schedulePlanes.filter((item: SchedulePlane) =>
        item.status.match(/^active$/gi)
      );
    }
    return [];
  }

  onChangeSchedulePlanes(param: boolean) {
    this.schedulePlanes = param ? [new SchedulePlane()] : [];
  }

  // data detail
  isLoading = false;
  errorCause = "";
  async fetchDetailData() {
    this.errorCause = "";
    this.isLoading = true;
    try {
      const res = await CargoConfigurationController.getDetailCargoConfiguration(
        this.id
      );
      this.form = res;
      this.schedulePlanes = this.form.schedulePlaneForm;
      this.onMappingClient(this.form);
    } catch (err) {
      this.errorCause = parsingErrorResponse(err).type;
    } finally {
      this.isLoading = false;
    }
  }

  clientValue: any = "";
  onSearchClientName = debounce((value: string) => {
    if (!value || value.length >= 3) {
      ClientController._onGetCLientMappingList(
        new RequestApiClientMappingList({
          page: 1,
          limit: 10,
          search: value,
          isParent: true,
        })
      );
    }
  }, 250);

  get isLoadingClientPartner() {
    return ClientController.isLoading;
  }

  get isInvalidVolumeOrWeight() {
    return (
      +this.form.minimumWeight.replace(",", ".") < 0.1 ||
      +this.form.maximumWeight.replace(",", ".") < 0.1 ||
      +this.form.minimumVolume.replace(",", ".") < 0.1 ||
      +this.form.maximumVolume.replace(",", ".") < 0.1
    );
  }

  get mappedDataClient() {
    return ClientController.clientMappingData.map((client: ClientMappingList) => ({
      id: client.clientId,
      name: client.clientName,
      code: client.clientCode,
      display: `${client.clientCode}-${client.clientName?.toUpperCase()}`,
      isActive: false,
    }));
  }

  get mappedDataClientBranch() {
    return ClientController.clientData.data
      .map((client: ClientData) => ({
        id: client.id,
        name: client.companyName,
        code: client.code,
        display: `${client.code}-${client.companyName?.toUpperCase()}`,
        isActive: false,
        status: client.status,
      }))
      .filter((item: any) => item.status === "approved");
  }

  // client branch
  clientBranchValue: Array<any> = [];
  fetchClientBranch(search: string) {
    if (typeof this.clientValue === "object" && this.clientValue.name.length) {
      ClientController._onGetList({
        page: 1,
        limit: 10,
        search,
        status: "",
        clientParentId: this.clientValue?.id,
      });
      return;
    }
    ClientController._onGetCLientMappingList(
      new RequestApiClientMappingList({
        page: 1,
        limit: 10,
        search,
        isParent: false,
        isBranch: true,
      })
    );
  }
  filterClientBranch = debounce((search: string) => {
    if (search.length > 2 || !search) this.fetchClientBranch(search);
  }, 250);
  
  get clientBranchOptions() {
    let data: any = [];
    if (typeof this.clientValue === "object" && this.clientValue.name.length) {
      data = this.mappedDataClientBranch;
      data = [
        {
          id: "all",
          display: "Semua",
        },
        ...data,
      ];
    } else {
      data = this.mappedDataClient;
    }
    return data;
  }

  async onChangeClientBranch(value: any) {
    if (this.clientBranchValue.length <= 1) {
      const data = value[0]?.id
        ? await ClientManagementController.getDetail(value[0]?.id)
        : false;
      if (data) {
        this.clientValue = {
          id: this.detailData.clientParentId,
          name: this.detailData.clientParentName,
          code: this.detailData.clientParentCode,
          display: `${
            this.detailData.clientParentCode
          }-${this.detailData.clientParentName?.toUpperCase()}`,
          isActive: false,
        };
        this.fetchClientBranch("");
      }
    }
  }

  get mappedCargoConfigurationClientBranch() {
    if (this.clientBranchValue.filter(e => e.id === 'all').length > 0) return [];
    return this.clientBranchValue.map((item: any) => ({
      cc_client_branch_id: item.id,
      cc_client_branch_name: item.name,
    }));
  }

  isValidWeightVolume(val: string) {
    return val.length && +val.replace(",", ".") < 0.1;
  }

  onMappingClient(form: CargoConfiguration) {
    this.clientValue = this.form.clientParentId > 0 ? {
        id: form.clientParentId,
        name: form.clientParentName,
        code: `CL${form.clientParentId}`,
        display: `CL${form.clientParentId}-${form.clientParentName?.toUpperCase()}`,
        isActive: false,
      } : "";
      this.clientBranchValue = form.clientBranch.map((client: ClientBranch) => ({
      id: client.clientBranchId,
      name: client.clientBranchName,
      code: `CL${client?.clientBranchId}`,
      display: `CL${client?.clientBranchId}-${client.clientBranchName?.toUpperCase()}`,
      isActive: false,
    }));
  }

  // Detail Data Client Management
  get detailData() {
    return ClientManagementController.detailClientManagement;
  }


  // delete config
  deleteConfigModal = false;
  configSelected: any = "";

  deleteConfig() {
    this.configSelected = {
      configName: `${this.form.originCity.code} - ${this.form.destinationCity.code}`,
      id: this.id,
      type: "edit"
    };
    this.deleteConfigModal = true;
  }
  get isEditAble() {
    return checkRolePermission(CARGO_CONFIGURATION.EDIT);
  }
}
